import React, { useState } from "react";
import $ from "jquery";

function Contact() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    function submitForm() {
        $(".success-message").hide();
        $(".failure-message").hide();

        $.post("https://cynthiamcmahon.ca/api/contact.php", {
            name: name,
            email: email,
            message: message,
        })
            .done(function (data) {
                console.log(data);
                if (data === "OK") {
                    $(".success-message").show();
                } else {
                    $(".failure-message").show();
                }
            })
            .fail(function (response) {
                $(".failure-message").show();
            });
    }

    return (
        <section>
            <div>
                <div class='contact-form'>
                    <h1>Contact Us</h1>
                    <p>
                        Have a question, comment, or concern? We would be happy
                        to hear from you! All fields in the contact form are
                        required.
                    </p>
                    <hr class='divider' />
                    <form
                        id='contact-form'
                        class='u-clearfix u-form-spacing-10 form-vertical'
                    >
                        <div
                            class='validation-summary-valid text-danger'
                            data-valmsg-summary='true'
                        ></div>{" "}
                        <div class='form-group form-name'>
                            <label htmlFor='name-3b9a' className='label'>
                                Name
                            </label>
                            <input
                                type='text'
                                placeholder='Enter your Name'
                                id='name-3b9a'
                                name='name'
                                class='text-input'
                                required=''
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                        <div class='u-form-email u-form-group'>
                            <label htmlFor='email-3b9a' className='label'>
                                Email
                            </label>
                            <input
                                type='email'
                                placeholder='Enter a valid email address'
                                id='email-3b9a'
                                name='email'
                                class='text-input'
                                required=''
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div class='u-form-group u-form-message'>
                            <label htmlFor='message-3b9a' className='label'>
                                Message
                            </label>
                            <textarea
                                placeholder='Enter your message'
                                rows='4'
                                id='message-3b9a'
                                name='message'
                                class='text-area'
                                required=''
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                            ></textarea>
                        </div>
                        <div class='u-align-left u-form-group u-form-submit'>
                            <input
                                type='button'
                                value='Submit'
                                class='submit-button'
                                onClick={() => submitForm()}
                            />
                        </div>
                        <div class='form-send-message success-message'>
                            {" "}
                            Thank you! Your message has been sent successfully.{" "}
                        </div>
                        <div class='failure-message form-send-message'>
                            {" "}
                            Unable to send your message. Please try again.{" "}
                        </div>
                    </form>{" "}
                </div>
            </div>
        </section>
    );
}

export default Contact;
