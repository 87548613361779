import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import $ from "jquery";
import "./App.css";
import Home from "./Home.js";
import NotFound from "./NotFound";
import { GoThreeBars } from "react-icons/go";
import Games from "./Games";
import Quintessential from "./Quintessential";
import ShadowMonsters from "./ShadowMonsters";
import SilverProphet from "./SilverProphet";
import LostFate from "./LostFate";
import Contact from "./Contact";

function toggleMobile() {
    $("#mobile-links").toggle();
}

function App() {
    return (
        <Router>
            <nav id='full-nav'>
                <div className='nav-caption'>
                    <span className='nav-logo'>CYNTHIA MCMAHON</span>
                </div>
                <div className='nav-links'>
                    <span></span>
                    <Link to='/'>HOME</Link>
                    <a href='https://cynthiamcmahon.ca/blog/'>BLOG</a>
                    <Link to='/games/'>GAMES</Link>
                    <Link to='/contact/'>CONTACT</Link>
                    <span></span>
                </div>
            </nav>
            <nav id='mobile-nav'>
                <div id='mobile-nav-container'>
                    <div className='mobile-logo'>CYNTHIA MCMAHON</div>
                    <span></span>
                    <button
                        id='mobile-button'
                        name='mobile-button'
                        aria-label='toggle mobile menu'
                        onClick={toggleMobile}
                    >
                        <GoThreeBars />
                    </button>
                </div>
            </nav>
            <div id='mobile-links'>
                <Link className='nav-link' to='/'>
                    HOME
                </Link>
                <Link className='nav-link' to='/blog/'>
                    BLOG
                </Link>
                <Link className='nav-link' to='/games'>
                    GAMES
                </Link>
                <Link className='nav-link' to='/contact/'>
                    CONTACT
                </Link>
            </div>
            <Switch>
                <Route exact path='/'>
                    <Home />
                </Route>
                <Route path='/games'>
                    <Games />
                </Route>
                <Route path='/quintessential'>
                    <Quintessential />
                </Route>
                <Route path='/lostfate'>
                    <LostFate />
                </Route>
                <Route path='/silverprophet'>
                    <SilverProphet />
                </Route>
                <Route path='/shadowmonsters'>
                    <ShadowMonsters />
                </Route>
                <Route path='/contact'>
                    <Contact />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
