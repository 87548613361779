import React, { useState, useEffect } from "react";
import ImageLoader from "./ImageLoader";

function SilverProphet() {
    const [toggleParagraph, setToggleParagraph] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main id='content' className='container'>
            <h1>Silver Prophet</h1>
            <div className='large'>
                <ImageLoader
                    src='https://psiberneticgames.com/Content/SilverProphet.png'
                    thumb='data:image.png/;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsEAAA7BAbiRa+0AAAF9SURBVChTHZBbS1tREIVnX84+lyQQNRoTS1tB+tBSQVtQ/4A/zN8lCD71qY8q2EItiTnxdpJ4bvsys934MjDMmsX6Fjsb7vhOdhWrQVXFo/HR49PKml/lq/P+W5quC7kknzDel5IAFs6J017/6+ZWfvxzam26vtYo9XsyeSS/qRIkqD1+VOkgisZSxYzfWc3nzl4WL31tx3t7Oz8O//65/Y+kGX8muLaGAQxl9E+3N23TEmnyImLcOxdHKtv9tDq/yI2RhCOpavCztikJF0jXun1B1+Hi1rTie5JxgLKqhHW6rO+nk9H+l/jzMBtvBIy17eGdM8uyzrjocrFAFNtR3BDVrTZEJn8YiGhZlVrGxjkQYByZp6IDHBM1b9p77tlB0tuQcoWI4IP63cauyO2e7OOy7HW75rWKAkq/UzZBZEVIBsCMp8b7Al1FhMBC4jhNXNFMZ3Mt5MJoYiqf5Q/zZ/ZBJj58AHAWBqSMhxpqwrAEr4JsIBHA3o/gPbwBFqrhdE/NcLkAAAAASUVORK5CYII='
                    alt='Silver Prophet promotional image'
                />
            </div>
            <h2 className='center purple'>Coming Soon!</h2>
            {!toggleParagraph ? (
                <section className='toggle'>
                    <article className='white showMore'>
                        <p>
                            For a thousand years, the world has known peace. It
                            now stands on the precipice of annihilation with the
                            return of Malpharion and his three sons. The Silver
                            Prophet, who defeated Malpharion and sealed him away
                            deep in his crystal prison foretold of a great hero
                            who would rise and defeat Malpharion for all time.
                        </p>
                        <p>
                            Silver Prophet is a tactical role-playing game where
                            you must rise and defeat the ancient evil Malpharion
                            who is bent on destroying the world by releasing a
                            demonic horde. Battle against the Three Brothers,
                            his sons, to reach Malpharion and prevent this
                            cataclysmic event.
                        </p>
                        <p>Coming soon to PC and Android!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(true)}
                    >
                        Show less...
                    </span>
                </section>
            ) : (
                <section className='toggle'>
                    <article className='white showLess'>
                        <p>
                            For a thousand years, the world has known peace. It
                            now stands on the precipice of annihilation with the
                            return of Malpharion and his three sons. The Silver
                            Prophet, who defeated Malpharion and sealed him away
                            deep in his crystal prison foretold of a great hero
                            who would rise and defeat Malpharion for all time.
                        </p>
                        <p>
                            Silver Prophet is a tactical role-playing game where
                            you must rise and defeat the ancient evil Malpharion
                            who is bent on destroying the world by releasing a
                            demonic horde. Battle against the Three Brothers,
                            his sons, to reach Malpharion and prevent this
                            cataclysmic event.
                        </p>
                        <p>Coming soon to PC and Android!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(false)}
                    >
                        Show more...
                    </span>
                </section>
            )}
        </main>
    );
}

export default SilverProphet;
