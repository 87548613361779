import React, { useState, useEffect } from "react";
import ImageLoader from "./ImageLoader";

function Quintessential() {
    const [toggleParagraph, setToggleParagraph] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main id='content' className='container'>
            <h1>Quintessential Duels</h1>
            <div className='large cover-image'>
                <ImageLoader
                    src='https://psiberneticgames.com/Content/CoverImage.png'
                    thumb=' data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAOTSURBVDhPDZNJbBtlAIXnn7H/mfHMeLzEY+ySxcZJ2iiUKFUVClUrKIUDVRHiguDEHQRISL1y5MCJAxxQbyAh9UJKOERIETQ0TtQQqSklrbM5yXhfZl//mfmx9G7v8KTvvQeWf/np5x+/zd18f+nq0rO19e3Vx29++kFju6s2xFQ6zTIMQQBFUZvNMwjhVGkyl0sxDB2GlK654MTQtu7eWV/ftYN3xWlfqT04HMZmlxZON/24AGGcCcOIJEnPdYBPhMBNCFy5MF1ZqDB0lspfuTw/f7W5GXuwtXLOfkfts+f5G+3nAyESHUUjdci6XKBZ59kluf2Mx0nOzaqGghkiCCBVeu3Vf+t1NdQyw7md1nIYxyi0m8OnQ6uheU3L0wyvp7s9FPkDVwY4ZiHdJz1RyoUBTX3z/XcEDjx5F6M1lh1Art2xH1/Mf8SSqa73xA5kgZYsMAhEO5HJYI6EGXq8UipNVjCG4M7dH9gkH3nu4OC/tLJfZIKe6rb23gtDPJLpaCiyaFrwzinlqcV8vsDziVictq2o29HBia52uq2+rtue26jXctHgZPvh/m7ioCV7DpfLwlicDELO46SX518vl8sj5giFpun6fgBqLRky0HRsXVP6vnN4dKx0esrZET7ckjIiLwhZ6UXLdH5bMwrj16V8KUZRqXQSocC2HHBvp5rNphvy6c7vv9bWNyy5off1hJiYuDBx49ocX36FE5LT+ZwA4/W9R/f/Mlr6JZ7nHdc2DJ1q/f3n09r+0UZ1ElqzE9yttxfzUmY4sHr1vjd3ffXeYcL5h8T6o4cbNOU/qakBNaOoQ1UdYIypz778ojg+obRbxy1VZYtWtsTmU5feuKymi8bqCk1zfXRhZpyxzMAIkwfti4BkPM8BAEi5Avjwk4/d6h9MDPScyA0xwiM3MSal3lrMi2zy+XJVn73yQuUluTcbBRoJC7ZtdbuNUWxpaoYaM7rXivEsQ6ZpQBBEGOHI9w1VF0R+akq0AT05xgzlRnqybNgiQm67fdZs75vmkCBiVDE7xkNMa8gNCQmQiThOhRhi3DztN8+UHIyZIUrQRP+kQWPQ6chEZ48L3YiXWDZJ3br9dfu4KhBR4AXQixAFsiSV98MkTQqjN3S1wA96fUoEudFcjF6TsYdp39Q4CVA0+Orzlba8yaH7tGOyDhrVSACAR0AIggQE8nzPDxRxQajcdr34qFvbsZCPSBJCyP0PW3kDEAFmOXcAAAAASUVORK5CYII='
                    alt='Quintessential Duels cover image'
                />
            </div>
            <h2 className='center purple'>Available on PC and Google Play!</h2>
            {!toggleParagraph ? (
                <section className='toggle'>
                    <article className='white showMore'>
                        <p>
                            In a world where only the strong survive you must
                            carve your destiny from your foes. Can you defeat
                            all of your opponents to become the champion? Duel
                            others like you in search of the rare cards and
                            strengthen your deck. The object of a duel is to
                            capture as many cards on the battlefield as
                            possible. You capture cards by playing cards with
                            greater power than adjacent cards. Beware of the
                            special plus and same rules!
                        </p>
                        <p>
                            Quintessential Duels is a free-to-play collectible
                            card game. You play against 32 AI opponents to win
                            new cards and grow your collection. Can you collect
                            them all?
                        </p>
                        <p>Available on PC and Google Play!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(true)}
                    >
                        Show less...
                    </span>
                </section>
            ) : (
                <section className='toggle'>
                    <article className='white showLess'>
                        <p>
                            In a world where only the strong survive you must
                            carve your destiny from your foes. Can you defeat
                            all of your opponents to become the champion? Duel
                            others like you in search of the rare cards and
                            strengthen your deck. The object of a duel is to
                            capture as many cards on the battlefield as
                            possible. You capture cards by playing cards with
                            greater power than adjacent cards. Beware of the
                            special plus and same rules!
                        </p>
                        <p>
                            Quintessential Duels is a free-to-play collectible
                            card game. You play against 32 AI opponents to win
                            new cards and grow your collection. Can you collect
                            them all?
                        </p>
                        <p>Available on PC and Google Play!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(false)}
                    >
                        Show more...
                    </span>
                </section>
            )}
            <section className='downloads'>
                <h3>Downloads</h3>
                <article>
                    <a href='/downloads/x86/qd_setup.exe'>
                        32-bit Installer for Quintessential Duels
                    </a>
                </article>
                <article>
                    <a href='/downloads/x64/qd_setup.exe'>
                        64-bit Installer for Quintessential Duels
                    </a>
                </article>
                <article>
                    <a
                        href='https://play.google.com/store/apps/details?id=com.psiberneticgames.quintessentialduels'
                        aria-label='Link to download Qunitessential Duels from Google Play'
                        className='google-play'
                    >
                        <img
                            src='https://psiberneticgames.com/Content/Google_Play.png'
                            class='google-play'
                            alt='Google Play Download link'
                        />
                    </a>
                </article>
            </section>
        </main>
    );
}

export default Quintessential;
