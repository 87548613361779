import React, { useState, useEffect } from "react";
import ImageLoader from "./ImageLoader";

function LostFate() {
    const [toggleParagraph, setToggleParagraph] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main id='content' className='container'>
            <h1>Lost Fate</h1>
            <div className='large'>
                <ImageLoader
                    src='https://psiberneticgames.com/Content/LostFate.png'
                    thumb='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAFvSURBVChTDdDfbtMwFIDxc+wkjumf0VxUot2QNmCTEOrut4fjAXg9JC6qCYG0lrTJ0myJYzv2OfQBvovfh+vvP0AK8I4BQGuQEhDZW8xyCI6jAdPyaykWNyAyFEpOHjdM/T3s+v3vwpRf7J90+/Pa75pq59Cz64BHemtAC3avDE6+29yxHya2zqr9Lb3IukyC6Uw/zkWaktk/0+EZdA4xAiDbQerNRw6u+FpdfOLsr6sa0wtcZuM5bvfH+qVXl2tCCRR5HDgYmd+taBhODfzbDvpkDnU/YRtsZ014Oobhzd4vjqnrWi9QMAeP02+XSCMrmaBkEc9gjpBwXM5dJfTYJNczW4YkjfRhpX8dtcwKRTYCEQuaFYSpZDPmwfkOlA1KhSSPaoH5Rax7D2bAq4cC5gg37xNBULZ8PulhuQ3tDPQApqGemFlwinKK2VTg+nOSeThNUK3wDLMViAOrBKZzbq8kREYL4xPIJcMt0gH+Ax7w5U6mTUQ2AAAAAElFTkSuQmCC'
                    alt='Lost Fate promotional image'
                />
            </div>
            <h2 className='center purple'>Coming Soon!</h2>
            {!toggleParagraph ? (
                <section className='toggle'>
                    <article className='white showMore'>
                        <p>
                            The world has gone mad! Powerful mages have usurped
                            fate and battle each other for dominance over their
                            destinies. They summon powerful creatures to their
                            aid and cast devastating spells. You are such a mage
                            and have an arsenal of these spells to unleash on
                            your opponents.
                        </p>
                        <p>
                            Lost Fate is a free-to-play collectible card game.
                            Battle against AI opponents or take your game to the
                            next level and challenge other players in the arena.
                            Only one can be the master of fate. Don't like your
                            cards? Trade them on the market with other players!
                        </p>
                        <p>Coming soon to PC and Android!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(true)}
                    >
                        Show less...
                    </span>
                </section>
            ) : (
                <section className='toggle'>
                    <article className='white showLess'>
                        <p>
                            The world has gone mad! Powerful mages have usurped
                            fate and battle each other for dominance over their
                            destinies. They summon powerful creatures to their
                            aid and cast devastating spells. You are such a mage
                            and have an arsenal of these spells to unleash on
                            your opponents.
                        </p>
                        <p>
                            Lost Fate is a free-to-play collectible card game.
                            Battle against AI opponents or take your game to the
                            next level and challenge other players in the arena.
                            Only one can be the master of fate. Don't like your
                            cards? Trade them on the market with other players!
                        </p>
                        <p>Coming soon to PC and Android!</p>
                    </article>
                    <span
                        className='toggleButton'
                        onClick={() => setToggleParagraph(false)}
                    >
                        Show more...
                    </span>
                </section>
            )}
        </main>
    );
}

export default LostFate;
