import React from "react";
import { FaCog, FaGem, FaMobileAlt, FaSun, FaAtom } from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import MakeSomethingPowerful from "./Content/Make Something Powerful.png";
import WorkStation from "./Content/Work Station.jpg";
import Student from "./Content/student.jpg";

function Home() {
    // var currentCard = 1;

    // function change_image() {
    //     $("#slider-" + currentCard).fadeOut(1000);
    //     currentCard++;
    //     if (currentCard > 3) currentCard = 1;
    //     $("#slider-" + currentCard).fadeIn(1500);
    // }

    // window.setInterval(function () {
    //     /// call your function here
    //     change_image();
    // }, 4000);

    return (
        <>
            <div id='slider'>
                <div id='slider-1'>
                    <img
                        className='full-width'
                        src={MakeSomethingPowerful}
                        alt='Make Something Power - Our team of web designers and developers will build something powerful for you.'
                    />
                </div>
                <div className='content'>
                    <div className='column'>
                        <FaGem className='react-icon' />
                        <div className='title'>
                            Get <span className='bold highlight'>VISIBLE</span>
                        </div>
                        <div className='oblique'>
                            Building a commanding presence on the internet to
                            bring customers to your business
                        </div>
                    </div>
                    <div className='column'>
                        <FaCog className='react-icon' />
                        <div className='title'>
                            Creativity{" "}
                            <span className='bold highlight'>UNLEASHED</span>
                        </div>
                        <div className='oblique'>
                            Desiging stunning and unique websites that stand out
                            amongst the crowd
                        </div>
                    </div>
                </div>
                <div id='slider-2'>
                    <img
                        className='full-width'
                        src={WorkStation}
                        alt='Work Station - person working at a work station.'
                    />
                </div>
                <div className='content'>
                    <div className='column'>
                        <FaSun className='react-icon' />
                        <div className='title'>
                            Enhancing{" "}
                            <span className='bold highlight'>BRANDS</span>
                        </div>
                        <div className='oblique'>
                            Taking your brand to the next level with a digital
                            presense that customers will identify with
                            excellence
                        </div>
                    </div>
                    <div className='column'>
                        <FaMobileAlt className='react-icon' />
                        <div className='title'>
                            Modern{" "}
                            <span className='bold highlight'>DESIGNS</span>
                        </div>
                        <div className='oblique'>
                            Sleek modern designs built using the latest
                            technologies amd methodologies
                        </div>
                    </div>
                </div>
                <div id='slider-3'>
                    <img
                        className='full-width'
                        src={Student}
                        alt='Laptop - person working at a laptop.'
                    />
                </div>
                <div className='content'>
                    <div className='column'>
                        <FiGlobe className='react-icon' />
                        <div className='title'>
                            Get <span className='bold highlight'>MOBILE</span>
                        </div>
                        <div className='oblique'>
                            Responsive websites and apps that look and feel
                            great on mobile devices
                        </div>
                    </div>
                    <div className='column'>
                        <FaAtom className='react-icon' />
                        <div className='title'>
                            Ligntning{" "}
                            <span className='bold highlight'>SPEED</span>
                        </div>
                        <div className='oblique'>
                            Fastest page loads possible
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
