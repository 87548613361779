import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "./ImageLoader";

function Games() {
    //const context = useContext(WebSiteContext);
    return (
        <main id='content' className='container'>
            <h1>Games</h1>
            <div id='game-tiles' className='flex-container'>
                <div className='holder'>
                    <Link to='/quintessential'>
                        <ImageLoader
                            src='https://psiberneticgames.com/Content/QuintessentialDuels.png'
                            thumb='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAGrSURBVChTDc/NTtRAHADwmf9MO51uux9dukpdWgtRPkQTNYiYGKPRg4nG6EVO3Lz4SLyAB48efAMPrgkGjQjKAcXNSs1uaFq6O52Zui/wS3742f0X/pXr9mBvt/RFKfI81UpKMbG5BahSGAzD3jn4y2wPEe40ffLg6eaofxitrkfn2zMWqtXMpYUoDDphd86gaOPW7Ukx/tU/wUCBGIzX8L2bT9Z4ws6G1WwMl9b+fPn4/jAJggs/f+xhYkoNQlFEXGy4CFuEe4R5i2J0ZFZiJgghWvcXlufnuitXr+1/+zwa/lOqwpRPbYRpVaoKEfwonrd1JpRu2dasUys9+wZ3t3ePev2BrAgijFgd0+0AawoBmDXIy4te2zJzkEt1J5djXZasFM83Ljfj4NP+CWV1ajWY3VAVNew6d1t4++7qwWnW6uIs1banF2N/TKIwV1vvvufUwcSSyJwILCVgYJj78CE5DR1+nIrHD8PC7Lx6g46TwVdIR7gxtRVieZqXRYZ0Mc0wt0ler8S9JE3P4Jxv3FluDwt4u6N6v2WmaqWC6QFhU2sK4ILjY8P8D19HpfjCXPIpAAAAAElFTkSuQmCC'
                            alt='Quintessential Duels promotional image'
                        />
                    </Link>
                </div>
                <div className='holder'>
                    <Link to='/lostfate'>
                        <ImageLoader
                            src='https://psiberneticgames.com/Content/LostFate.png'
                            thumb='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAFvSURBVChTDdDfbtMwFIDxc+wkjumf0VxUot2QNmCTEOrut4fjAXg9JC6qCYG0lrTJ0myJYzv2OfQBvovfh+vvP0AK8I4BQGuQEhDZW8xyCI6jAdPyaykWNyAyFEpOHjdM/T3s+v3vwpRf7J90+/Pa75pq59Cz64BHemtAC3avDE6+29yxHya2zqr9Lb3IukyC6Uw/zkWaktk/0+EZdA4xAiDbQerNRw6u+FpdfOLsr6sa0wtcZuM5bvfH+qVXl2tCCRR5HDgYmd+taBhODfzbDvpkDnU/YRtsZ014Oobhzd4vjqnrWi9QMAeP02+XSCMrmaBkEc9gjpBwXM5dJfTYJNczW4YkjfRhpX8dtcwKRTYCEQuaFYSpZDPmwfkOlA1KhSSPaoH5Rax7D2bAq4cC5gg37xNBULZ8PulhuQ3tDPQApqGemFlwinKK2VTg+nOSeThNUK3wDLMViAOrBKZzbq8kREYL4xPIJcMt0gH+Ax7w5U6mTUQ2AAAAAElFTkSuQmCC'
                            alt='Lost Fate promotional image'
                        />
                    </Link>
                </div>
                <div className='holder'>
                    <Link to='/silverprophet'>
                        <ImageLoader
                            src='https://psiberneticgames.com/Content/SilverProphet.png'
                            thumb='data:image.png/;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsEAAA7BAbiRa+0AAAF9SURBVChTHZBbS1tREIVnX84+lyQQNRoTS1tB+tBSQVtQ/4A/zN8lCD71qY8q2EItiTnxdpJ4bvsys934MjDMmsX6Fjsb7vhOdhWrQVXFo/HR49PKml/lq/P+W5quC7kknzDel5IAFs6J017/6+ZWfvxzam26vtYo9XsyeSS/qRIkqD1+VOkgisZSxYzfWc3nzl4WL31tx3t7Oz8O//65/Y+kGX8muLaGAQxl9E+3N23TEmnyImLcOxdHKtv9tDq/yI2RhCOpavCztikJF0jXun1B1+Hi1rTie5JxgLKqhHW6rO+nk9H+l/jzMBtvBIy17eGdM8uyzrjocrFAFNtR3BDVrTZEJn8YiGhZlVrGxjkQYByZp6IDHBM1b9p77tlB0tuQcoWI4IP63cauyO2e7OOy7HW75rWKAkq/UzZBZEVIBsCMp8b7Al1FhMBC4jhNXNFMZ3Mt5MJoYiqf5Q/zZ/ZBJj58AHAWBqSMhxpqwrAEr4JsIBHA3o/gPbwBFqrhdE/NcLkAAAAASUVORK5CYII='
                            alt='Silver Prophet promotional image'
                        />
                    </Link>
                </div>
                <div className='holder'>
                    <Link to='/shadowmonsters'>
                        <ImageLoader
                            src='https://psiberneticgames.com/Content/ShadowMonsters.png'
                            thumb='data:image.png/;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAGZSURBVChTFZBPa9RAGIfnfWeSyf9kd1O6iUtaaD1VFv8exKs3QfwSHvwCnvwUngTBo34Hr4J4LVSpraJdaldY1q3NJpnMZDKuz/UHDz8eePr6w+L4E1IWjQsvGVthakx/OfsSFwfYSymqzaSbv1rW7nCHujGdXL+hROMGAbV5eznvlFr8OJp/+wytsmzbYqjaEgzUyzNKkfkDePL8ZXei267x8hiVUVqJP1f+btDNXImrYJgyi6/Lc8eOhFhv35/CszcfD9+9XS3mlPmOnzCf65a05qclRp0pTQ9ePCKIqAmxdX7nNjOiDIug5b5B3Lt3d5DvNcuL8xPObIvogazLMM2iNCeySpKIJRnNtiYUAMjmsN+prv71ffb1ELWy3UAKIWvBkexn4VbEO1FfnB7Tmw8e2zwyNCEsJB11vHi8XSB1i2wM6E/ya7em+3W1Xi1/AyF9D3T68BHzeBhzN2DUIa6HjoO7O6N0yBnTlRRn86UES4Kv0GnqK3jx6j2jDADDwGmadtOuN8ZidAP5r9xgqqpxuIUAdSP+ASossdJJqTqjAAAAAElFTkSuQmCC'
                            alt='Shadow Monsters promotional image'
                        />
                    </Link>
                </div>
            </div>
            {/* <h2 className='center blue'>Quintessential Duels</h2>
            <div className='containter'>
                <Link to='/quintessential'><img src={QuintessentialDuels} alt="Quintessential Duels" /></Link>
                <figcaption>Quintessential Duels</figcaption>
            </div>
            {
                !toggleDuels ?
                    <section className='toggle'>
                        <article className='white showMore'>
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras euismod luctus accumsan. Vestibulum risus urna, malesuada eu egestas ac, varius in justo. Nullam quis turpis vitae ipsum egestas consectetur quis et justo. Duis aliquet massa vel sem ullamcorper, efficitur consequat massa viverra. Vestibulum quis massa eget diam sagittis ultricies. Ut convallis, eros eget finibus egestas, sapien eros pretium lacus, quis commodo purus velit vel nisi. Etiam lacinia tellus sit amet sagittis maximus. Pellentesque egestas sagittis ex, a interdum tortor pharetra auctor. Phasellus tincidunt diam quis pretium auctor. Nam vitae erat dignissim est dignissim ornare nec non nibh. Nullam quis congue dui, ac euismod augue. Sed in ante urna. Aenean fermentum, ligula nec blandit eleifend, arcu tortor eleifend neque, at eleifend dui magna id nunc. Sed commodo libero nulla, quis efficitur nulla tristique sit amet.</p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleDuels(true)}>Show less...</span>
                    </section>
                    :
                    <section className='toggle'>
                        <article className='white showLess'>
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras euismod luctus accumsan. Vestibulum risus urna, malesuada eu egestas ac, varius in justo. Nullam quis turpis vitae ipsum egestas consectetur quis et justo. Duis aliquet massa vel sem ullamcorper, efficitur consequat massa viverra. Vestibulum quis massa eget diam sagittis ultricies. Ut convallis, eros eget finibus egestas, sapien eros pretium lacus, quis commodo purus velit vel nisi. Etiam lacinia tellus sit amet sagittis maximus. Pellentesque egestas sagittis ex, a interdum tortor pharetra auctor. Phasellus tincidunt diam quis pretium auctor. Nam vitae erat dignissim est dignissim ornare nec non nibh. Nullam quis congue dui, ac euismod augue. Sed in ante urna. Aenean fermentum, ligula nec blandit eleifend, arcu tortor eleifend neque, at eleifend dui magna id nunc. Sed commodo libero nulla, quis efficitur nulla tristique sit amet.</p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleDuels(false)}>Show more...</span>
                    </section>
            }
            <h2 className='center blue'>Lost Fate</h2>
            <Link to='/lostfate'><img src={LostFate} alt="Lost Fate" onClick={() => { changePage('lostfate') }} /></Link>
            <figcaption>Lost Fate</figcaption>
            {
                !toggleFate ?
                    <section className='toggle'>
                        <article className='white showMore'>
                            <p>Fusce faucibus congue risus scelerisque maximus. Quisque hendrerit ante porta pretium sodales. Donec hendrerit magna gravida elit porttitor mollis. Sed imperdiet tortor non pulvinar aliquam. Nam viverra ac lectus vel volutpat. Donec in dolor ut nisi interdum ullamcorper. Pellentesque rutrum lacus dui, fermentum finibus urna aliquam sed. Nulla pellentesque, augue sit amet fermentum malesuada, tellus nisl consequat est, a aliquet lectus ante non nisi. Fusce ullamcorper pulvinar pharetra. Pellentesque nec arcu auctor, interdum metus id, suscipit ligula. Vivamus interdum bibendum risus, sed venenatis turpis semper eget. Vivamus feugiat fringilla dui, a placerat est aliquam eu. Integer nec odio tristique, venenatis est vitae, scelerisque turpis. Etiam blandit, dolor in interdum ornare, nibh diam facilisis augue, quis dignissim neque orci id est. Nunc sit amet quam facilisis, scelerisque nisi placerat, consequat arcu. </p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleFate(true)}>Show less...</span>
                    </section>
                    :
                    <section className='toggle'>
                        <article className='white showLess'>
                            <p>Fusce faucibus congue risus scelerisque maximus. Quisque hendrerit ante porta pretium sodales. Donec hendrerit magna gravida elit porttitor mollis. Sed imperdiet tortor non pulvinar aliquam. Nam viverra ac lectus vel volutpat. Donec in dolor ut nisi interdum ullamcorper. Pellentesque rutrum lacus dui, fermentum finibus urna aliquam sed. Nulla pellentesque, augue sit amet fermentum malesuada, tellus nisl consequat est, a aliquet lectus ante non nisi. Fusce ullamcorper pulvinar pharetra. Pellentesque nec arcu auctor, interdum metus id, suscipit ligula. Vivamus interdum bibendum risus, sed venenatis turpis semper eget. Vivamus feugiat fringilla dui, a placerat est aliquam eu. Integer nec odio tristique, venenatis est vitae, scelerisque turpis. Etiam blandit, dolor in interdum ornare, nibh diam facilisis augue, quis dignissim neque orci id est. Nunc sit amet quam facilisis, scelerisque nisi placerat, consequat arcu. </p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleFate(false)}>Show mores...</span>
                    </section >
            } */}
        </main>
    );
}

export default Games;
